<template>
  <div class="modal">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>



.modal {
  @apply  bg-accent-1
          m-auto p-8 rounded-xl text-left drop-shadow-md flex flex-col box-border text-bkg-primary 
          w-[300px] sm:w-[500px]
          text-sm 
          transition-all ease-in-out duration-200 transform
          border
          /* w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-[360px] lg:h-[464px] */
}

</style>